import {useEffect, useState} from 'react';

const useShuffleText = (result: string, shuffleList: string[], isMixingText: boolean) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % shuffleList.length);
    }, 50);
    return () => clearInterval(intervalId);
  }, [shuffleList]);
  return isMixingText ? shuffleList[currentTextIndex] : result;
};

export default useShuffleText;
