import React, {useEffect} from 'react';
import styled from "styled-components";
import BackgroundImage from "@/assets/svgs/background.svg";
import Header from "@/components/layout/Header";
import {Column, Spacer, Text} from "@/components/base/Atomic";
import FillButton from "@/components/button/FillButton";
import useGameState from "@/hooks/useGameState";
import {useNavigate} from "react-router-dom";

const WaitingResultPage = () => {
  const gameState = useGameState();
  const navigate = useNavigate()

  useEffect(() => {
    if (gameState === 'show_result') {
      navigate('/result');
    }
  }, [gameState, navigate]);
  useEffect(() => {
    const interval =setInterval(() => {
      console.log('hi');
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Screen>
      <Header/>
      <Spacer $flex={4}/>
      <Column $alignItems={'center'}>
        <Text $weight={400} $size={34} $color={'#F7C514'} className={'PyeongChang'} style={{textAlign: 'center'}}>
          예측 결과 발표 및 <br/>실제 게임 진행
        </Text>
        <Spacer $height={10}/>
        <Text $weight={400} $size={14} $color={'white'} className={'PyeongChang'}>
          모두 앞을 봐주시기 바랍니다!
        </Text>
      </Column>
      <Spacer $flex={5}/>
      <FillButton text={'대기중...'} onPress={() => {}} disabled={true}/>
    </Screen>
  );
};

const Screen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100dvh;
    background-color: #121313;
    background-image: url(${BackgroundImage});
    background-repeat: repeat;
`;

export default WaitingResultPage;
