import React from 'react';
import styled from "styled-components";
import {Row, Text} from "@/components/base/Atomic";
import {ReactComponent as GoogleLogo} from "@/assets/svgs/google.svg";
import {firebaseAuth} from "@/lib/firebase";
import {
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {useNavigate} from "react-router-dom";


const GoogleLoginButton = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Button onClick={async () => {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(firebaseAuth, provider);
        navigate('/join');
      }}>
        <Row $gap={10} $alignItems={'center'}>
          <GoogleLogo/>
          <Text $weight={500} $size={14} $color={'white'}>학교 구글 계정으로 계속하기</Text>
        </Row>
      </Button>
    </Container>
  );
};

const Container = styled.div`
    width: 100%;
    padding: 30px 24px;
`;

const Button = styled.button`
    width: 100%;
    height: 52px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #262828;

    &:active {
        background-color: #505252;
    }
`;

export default GoogleLoginButton;
