import React, {useEffect, useState} from 'react';
import {Column, Text} from "@/components/base/Atomic";
import TeacherCard from "@/components/TeacherCard";
import useMatch from "@/hooks/useMatch";
import {deleteDoc, doc, setDoc} from 'firebase/firestore';
import {firestore, firebaseAuth} from "@/lib/firebase";

const MatchPrediction = () => {
  const {teacherA, teacherB} = useMatch();
  const [selectedTeacher, setSelectedTeacher] = useState(0);
  const user = firebaseAuth.currentUser;
  useEffect(() => {
    deleteDoc(doc(firestore, `game/match/${selectedTeacher === 1 ? 'b' : 'a'}/${user?.email}`)).then(() => {
      console.log('deleted');
    });
    setDoc(doc(firestore, `game/match/${selectedTeacher === 1 ? 'a' : 'b'}`, user?.email!), {}).then(
      () => {
        console.log('added');
      }
    );
  }, [selectedTeacher, user?.email]);
  return (
    <Column $padding={[0, 24]} $gap={15}>
      <Text $weight={400} $size={14} $color={'#F7C514'} className={'PyeongChang'}>
        대진 예측하기
      </Text>
      <TeacherCard
        teacher={teacherA}
        selected={selectedTeacher === 1}
        onClick={() => {
          setSelectedTeacher(1);
        }}/>
      <TeacherCard
        teacher={teacherB}
        selected={selectedTeacher === 2}
        onClick={() => {
          setSelectedTeacher(2);
        }}/>
    </Column>
  );
};

export default MatchPrediction;
