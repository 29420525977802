import React from 'react';
import styled from "styled-components";
import {Text} from "@/components/base/Atomic";

interface ButtonProps {
  text: string;
  onPress: () => void;
  disabled?: boolean;
}

const FillButton = ({text, onPress, disabled}: ButtonProps) => {
  return (
    <Container>
      <Button onClick={onPress} disabled={disabled}>
        <Text
          $weight={600}
          $size={16}
          $color={'white'}
        >{text}</Text>
      </Button>
    </Container>
  );
};

const Container = styled.div`
    width: 100%;
    padding: 30px 24px;
`;

const Button = styled.button`
    width: 100%;
    height: 52px;
    border-radius: 10px;
    border: none;
    background-color: #262828;
    &:disabled {
        background-color: #636363;
    }
    &:active {
        background-color: #636363;
    }
`;

export default FillButton;
