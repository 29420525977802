import React, {useEffect, useState} from 'react';
import useGameState from "@/hooks/useGameState";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import BackgroundImage from "@/assets/svgs/background.svg";
import Header from "@/components/layout/Header";
import {Column, Spacer, Text} from "@/components/base/Atomic";
import useGameInfo from "@/hooks/useGameInfo";
import useShuffleText from "@/hooks/useShuffleText";

const gameList = [
  '제기차기',
  '동전 던지기',
  '가위바위보',
  '디비디비딥',
  '참참참',
  '탕수육 게임',
  '눈치게임'
];

const DecideGamePage = () => {
  const gameState = useGameState();
  const navigate = useNavigate();
  const {name} = useGameInfo();
  const [isGameShuffle, setIsGameShuffle] = useState(true);
  const shuffleGame = useShuffleText(name, gameList, isGameShuffle);


  useEffect(() => {
    if (gameState === 'finish_decide_game') {
      setIsGameShuffle(false);
    }
    if (gameState === 'voting') {
      navigate('/voting');
    }
  }, [gameState, navigate]);


  return (
    <Screen>
      <Header/>
      <Spacer $flex={5}/>
      <Column $alignItems={'center'}>
        <Text $weight={400} $size={30} $color={'#F7C514'} className={'PyeongChang'}>
          대진 추첨중
        </Text>
        <Spacer $height={10}/>
        <Text $weight={400} $size={14} $color={'white'} className={'PyeongChang'}>
          두구두구...어떤 게임일까? 모두 앞을 봐주세요
        </Text>
        <Spacer $height={34}/>
        <GameBox>
          <Text $weight={400} $size={30} $color={'white'} className={'PyeongChang'}>
            {shuffleGame}
          </Text>
        </GameBox>
      </Column>
      <Spacer $flex={6}/>
    </Screen>
  );
};

const Screen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100dvh;
    background-color: #121313;
    background-image: url(${BackgroundImage});
    background-repeat: repeat;
`;

const GameBox = styled.div`
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 19px 41px;
    background-color: #505252;
`;


export default DecideGamePage;
