import styled from 'styled-components';

interface TextProps {
  $size?: number;
  $weight?: number;
  $color?: string;
}

export const Text = styled.p<TextProps>`
  font-weight: ${props => props.$weight || 500};
  font-size: ${props => props.$size || 14}px;
  color: ${props => props.$color || 'black'};
  line-height: 150%;
  white-space: pre-line;
`;


export const PyeongChangText = styled(Text)`
  font-family: 'PyeongChang';
`;
