import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import BackgroundImage from "@/assets/svgs/background.svg";
import Header from "@/components/layout/Header";
import {Column, Spacer, Text} from "@/components/base/Atomic";
import FillButton from "@/components/button/FillButton";
import {firebaseAuth} from "@/lib/firebase";
import {collection, doc, getDoc, onSnapshot, setDoc} from 'firebase/firestore';
import {firestore} from "@/lib/firebase";
import userGageState from "@/hooks/useGameState";
import {useNavigate} from "react-router-dom";

const JoinPage = () => {
  const user = firebaseAuth.currentUser;
  const gameState = userGageState();
  const navigate = useNavigate();
  const [joinStudentCount, setJoinStudentCount] = useState(0);
  useEffect(() => {
    const unsub = onSnapshot(collection(firestore, "game/state/join_list"), (snapshot) => {
      setJoinStudentCount(snapshot.docs.length);
    });
    return () => unsub();
  }, []);
  return (
    <Screen>
      <Header/>
      <Spacer $flex={9}/>
      <Column $gap={10} $alignItems={'center'}>
        <Text $weight={400} $size={30} $color={'#F7C514'} className={'PyeongChang'}>
          입장 대기중
        </Text>
        <Text $weight={400} $size={14} $color={'white'} className={'PyeongChang'}>
          시작될 때까지 조금만 기다려주세요
        </Text>
      </Column>
      <Spacer $flex={8}/>
      {gameState !== 'waiting' && <Text $weight={400} $size={14} $color={'white'} className={'PyeongChang'} style={{
        position: 'absolute',
        bottom: 100
      }}>
        {`입장 대기중 (${joinStudentCount}/117)`}
      </Text>}
      <FillButton
        text={`${formatStudentInfo(user?.displayName!)}으로 입장하기`}
        onPress={async () => {
          if (!user?.email) return;
          await setDoc(doc(firestore, "game/state/join_list", user.email), {});
          const data =  await getDoc(doc(firestore, 'students', user.email))
          if (!data.exists()) {
            await setDoc(doc(firestore, 'students', user.email), {
              name: user.displayName,
              score: 0,
            });
          }

          switch (gameState) {
            case 'started':
              navigate('/decide-game');
              break;
            case 'finish_decide_game':
              navigate('/decide-game');
              break;
            case 'voting':
              navigate('/voting');
              break;
            case 'finish_voting':
              navigate('/waiting-result');
              break;
            case 'show_result':
              navigate('/result');
              break;
            case 'finish':
              navigate('/finish');
              break;
          }
        }}
        disabled={gameState === 'waiting'}/>
    </Screen>
  );
};

const Screen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100dvh;
    background-color: #121313;
    background-image: url(${BackgroundImage});
    background-repeat: repeat;
`;

const formatStudentInfo = (input: string): string => {
  const grade = input[0];
  const classNum = input[2];
  const studentName = input.substring(5);

  return `${grade}학년 ${classNum}반 ${studentName}`;
};

export default JoinPage;
