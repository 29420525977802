import React from 'react';
import styled from "styled-components";
import Teacher from "@/lib/types/teacher";
import {ReactComponent as SwCharacter} from "@/assets/svgs/sw_char.svg";
import {Column, Text} from "@/components/base/Atomic";

interface TeacherCardProps {
  teacher: Teacher;
  onClick?: () => void;
  selected?: boolean;
}

const TeacherCard = ({teacher, selected, onClick}: TeacherCardProps) => {
  return (
    <Container onClick={onClick} $selected={selected}>
      <SwCharacter/>
      <Column $gap={4}>
        <Text $weight={500} $size={12} $color={'white'}>
          {teacher.description}
        </Text>
        <Text $weight={600} $size={18} $color={'white'}>
          {`${teacher.name} 선생님`}
        </Text>
      </Column>
    </Container>
  );
};

// const Container = styled.div`
//     width: 100%;
//     padding: 0 24px;
// `;

const Container = styled.div<{ $selected?: boolean }>`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    background-color: ${({$selected}) => $selected ? '#505252' : '#1D1D1D'};
    padding: 13px 14px;
    &:active {
        background-color: #404040;
    }
`;


export default TeacherCard;
