import React from 'react';
import styled from "styled-components";
import BackgroundImage from '@/assets/svgs/background.svg';
import {ReactComponent as Year} from '@/assets/svgs/year.svg';
import {Column, Spacer, Text} from "@/components/base/Atomic";
import GoogleLoginButton from "@/components/button/GoogleLoginButton";

const LoginPage = () => {
  return (
    <Screen>
      <Spacer $flex={3}/>
      <Column $gap={16} $alignItems={'center'}>
        <Year/>
        <Text
          $weight={400}
          $size={44}
          $color={'white'}
          className={'PyeongChang'}
          style={{
            textAlign: 'center'
          }}>
          소프트웨어과<br/>학과발표회
        </Text>
        <Text
          $weight={700}
          $size={16}
          $color={'#D0D0D0'}
          style={{
            fontFamily: 'GanwonEduAll',
            textAlign: 'center'
          }}>
          “2023년 소프트웨어과의 마지막 <br/>행사에 당신을 초대합니다”
        </Text>
      </Column>
      <Spacer $flex={2}/>
      <GoogleLoginButton/>
    </Screen>
  );
};

const Screen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100dvh;
    background-color: #121313;
    background-image: url(${BackgroundImage});
    background-repeat: repeat;
`;

export default LoginPage;
