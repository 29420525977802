// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDi2z1vtp2uBkx_QXEVX5ASVLDARNaJ_DA",
  authDomain: "hagisiro-e1461.firebaseapp.com",
  projectId: "hagisiro-e1461",
  storageBucket: "hagisiro-e1461.appspot.com",
  messagingSenderId: "621922248864",
  appId: "1:621922248864:web:f42c9fc94275bb76aac1c5",
  measurementId: "G-569NZV31EN"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);

export default firebase;
