import React, {useEffect} from 'react';
import styled from "styled-components";
import BackgroundImage from "@/assets/svgs/background.svg";
import Header from "@/components/layout/Header";
import {Column, Spacer, Text} from "@/components/base/Atomic";
import FillButton from "@/components/button/FillButton";
import useMatch from "@/hooks/useMatch";
import useGameState from "@/hooks/useGameState";
import {useNavigate} from "react-router-dom";

const ResultPage = () => {
  const {winner} = useMatch();
  const gameState = useGameState();
  const navigate = useNavigate()

  useEffect(() => {
    if (gameState === 'started') {
      navigate('/decide-game');
    }
    if (gameState === 'finish') {
      navigate('/finish');
    }
  }, [gameState, navigate]);

  return (
    <Screen>
      <Header/>
      <Spacer $flex={13}/>
      <Column $alignItems={'center'}>
        <Text $weight={400} $size={34} $color={'#F7C514'} className={'PyeongChang'} style={{textAlign: 'center'}}>
          {`${winner?.name} 선생님 승리!`}
        </Text>
        <Spacer $height={10}/>
        <Text $weight={400} $size={14} $color={'white'} className={'PyeongChang'}>
          예측에 성공한 분들에게는 점수를 드립니다.
        </Text>
      </Column>
      <Spacer $flex={14}/>
      <FillButton text={'대기중...'} onPress={() => {}} disabled={true}/>
    </Screen>
  );
};

const Screen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100dvh;
    background-color: #121313;
    background-image: url(${BackgroundImage});
    background-repeat: repeat;
`;

export default ResultPage;
