import {useEffect, useState} from 'react';
import {doc, getDoc, onSnapshot} from "firebase/firestore";
import {firestore} from "@/lib/firebase";
import Teacher from "@/lib/types/teacher";

const useMatch = () => {
  const [teacherA, setTeacherA] = useState<Teacher>(
    {
      name: '',
      description: '',
    }
  );
  const [teacherB, setTeacherB] = useState<Teacher>(
    {
      name: '',
      description: '',
    }
  );
  const [winner, setWinner] = useState<Teacher>(
    {
      name: '',
      description: '',
    }
  );
  useEffect(() => {
    const unsub = onSnapshot(doc(firestore, "game/match"), (snapshot) => {
      getDoc(doc(firestore, `teacher_info/${snapshot.data()!.a}`)).then((doc) => {
        setTeacherA(doc.data()! as Teacher);
      });
      getDoc(doc(firestore, `teacher_info/${snapshot.data()!.b}`)).then((doc) => {
        setTeacherB(doc.data()! as Teacher);
      });
      getDoc(doc(firestore, `teacher_info/${snapshot.data()!.winner}`)).then((doc) => {
        setWinner(doc.data()! as Teacher);
      });
    });
    return () => unsub();
  }, []);
  return {teacherA, teacherB, winner};
};

export default useMatch;
