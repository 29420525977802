import React, {useEffect} from 'react';
import LoginPage from "@/pages/LoginPage";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {firebaseAuth} from "@/lib/firebase";
import JoinPage from "@/pages/JoinPage";
import DecideGamePage from "@/pages/DecideGamePage";
import VotingPage from "@/pages/VotingPage";
import WaitingResultPage from "@/pages/WaitingResultPage";
import ResultPage from "@/pages/ResultPage";
import FinishPage from "@/pages/FinishPage";

function App() {
  const [isLogin, setIsLogin] = React.useState(false);
  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      console.log(user);
      if (!user) {
        setIsLogin(false);
        return;
      }
      const domain = user!.email?.split('@')[1];
      if (domain !== 'sunrint.hs.kr') {
        firebaseAuth.signOut().then(() => alert('학교 구글 계정으로 로그인해주세요'));
        return;
      }
      setIsLogin(!!user);
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={isLogin ? <JoinPage/> : <Navigate to={'/login'}/>}/>
          <Route path="/join" element={isLogin ? <JoinPage/> : <Navigate to={'/login'}/>}/>
          <Route path="/decide-game" element={isLogin ? <DecideGamePage/> : <Navigate to={'/login'}/>}/>
          <Route path="/voting" element={isLogin ? <VotingPage/> : <Navigate to={'/login'}/>}/>
          <Route path="/waiting-result" element={isLogin ? <WaitingResultPage/> : <Navigate to={'/login'}/>}/>
          <Route path="/result" element={isLogin ? <ResultPage/> : <Navigate to={'/login'}/>}/>
          <Route path="/finish" element={isLogin ? <FinishPage/> : <Navigate to={'/login'}/>}/>
          <Route path="/login" element={isLogin ? <Navigate to={'/join'}/> : <LoginPage/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
