import {useEffect, useState} from 'react';
import {doc, onSnapshot} from "firebase/firestore";
import {firestore} from "@/lib/firebase";
import GameState from "@/lib/types/gameState";

const useGameState = () => {
  const [gameState, setGameState] = useState<GameState>('waiting');
  useEffect(() => {
    const unsub = onSnapshot(doc(firestore, "game/state"), (doc) => {
      if (!doc.exists()) return;
      setGameState(doc.data()!.game_state);
    });
    return () => unsub();
  }, []);
  return gameState;
};

export default useGameState;
