import React from 'react';
import styled from "styled-components";
import {Column, Row, Spacer, Text} from "@/components/base/Atomic";
import {ReactComponent as Year} from "@/assets/svgs/small_year.svg";
import {firebaseAuth} from "@/lib/firebase";

const Header = () => {
  return (
    <Column $padding={[0, 24]} $fill={true}>
      <Spacer $height={24}/>
      <Row $justifyContent={'space-between'} $alignItems={'center'} $fill={true}>
        <Row $alignItems={'center'} $gap={8}>
          <Year/>
          <Text $weight={400} $size={14} $color={'white'} className={'PyeongChang'}>
            소프트웨어과 학과발표회
          </Text>
        </Row>
        <LogoutButton onClick={async () => {
          await firebaseAuth.signOut();
        }}>
          로그아웃
        </LogoutButton>
      </Row>
      <Spacer $height={20}/>
      <div style={{width: '100%', height: 1, backgroundColor: '#4B4944'}}/>
    </Column>
  );
};


const LogoutButton = styled.button`
    display: flex;
    border: none;
    font-size: 14px;
    font-weight: 600;
    color: #4B4944;
    background-color: transparent;
`;
export default Header;
