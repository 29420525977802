import React from 'react';
import styled from "styled-components";
import BackgroundImage from '@/assets/svgs/background.svg';
import Header from "@/components/layout/Header";
import {Column, Spacer, Text} from "@/components/base/Atomic";
import FillButton from "@/components/button/FillButton";

const FinishPage = () => {
  return (
    <Screen>
      <Header />
      <Spacer $flex={4} />
      <Column $alignItems={'center'} $gap={10}>
        <Text $weight={400} $size={34} $color={'#F7C514'} className={'PyeongChang'}>
          안녕히 가세요
        </Text>
        <Text $weight={400} $size={16} $color={'white'} className={'PyeongChang'}>
          모두 수고하셨어요! 아쉽지만 오늘은 여기까지
        </Text>
      </Column>
      <Spacer $flex={5} />
      <FillButton text={'나가기'} onPress={()=>{
        // window.close();
      }}/>
      {/*모두 수고하셨어요! 아쉽지만 오늘은 여기까지*/}
    </Screen>
  );
};

const Screen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100dvh;
    background-color: #121313;
    background-image: url(${BackgroundImage});
    background-repeat: repeat;
`;

export default FinishPage;
