import {useEffect, useState} from 'react';
import {doc, getDoc, onSnapshot} from "firebase/firestore";
import {firestore} from "@/lib/firebase";

const useGameState = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  useEffect(() => {
    const unsub = onSnapshot(doc(firestore, "game/state"), async (snapshot) => {
      const data = snapshot.data()!;
      const index = data.game_index;
      const gameInfo = await getDoc(doc(firestore, `game_info/${index}`));
      setName(gameInfo.data()!.name);
      setDescription(gameInfo.data()!.description.replace(/\\n/g, '\n'));
    });
    return () => unsub();
  }, []);
  return {name, description};
};

export default useGameState;
