import React, {useEffect} from 'react';
import styled from "styled-components";
import BackgroundImage from "@/assets/svgs/background.svg";
import Header from "@/components/layout/Header";
import useGameState from "@/hooks/useGameState";
import {useNavigate} from "react-router-dom";
import {Column, Spacer, Text} from "@/components/base/Atomic";
import useGameInfo from "@/hooks/useGameInfo";
import MatchPrediction from "@/components/MatchPrediction";
import FillButton from "@/components/button/FillButton";

const VotingPage = () => {
  const {name, description} = useGameInfo();

  const gameState = useGameState();
  const navigate = useNavigate()

  useEffect(() => {
    if (gameState === 'finish_voting') {
      navigate('/waiting-result');
    }
  }, [gameState, navigate]);

  return (
    <Screen>
      <Header/>
      <Spacer $height={24}/>
      <Column $padding={[0, 24]} $gap={16}>
        <Text $weight={400} $size={30} $color={'#F7C514'} className={'PyeongChang'}>
          {name}
        </Text>
        <Text $weight={400} $size={14} $color={'white'} className={'PyeongChang'}>
          {description}
        </Text>
      </Column>
      <Spacer $height={30}/>
      <MatchPrediction/>
      <Spacer $flex={1}/>
      <FillButton text={'투표중...'} onPress={() => {
      }} disabled={true}/>
    </Screen>
  );
};


const Screen = styled.div`
    display: flex;
    flex-direction: column;
    //align-items: center;
    width: 100%;
    height: 100dvh;
    background-color: #121313;
    background-image: url(${BackgroundImage});
    background-repeat: repeat;
`;

export default VotingPage;
